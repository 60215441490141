import React from 'react';
import { FallingLines } from 'react-loader-spinner';

const Spinner = () => {
	return (
		<FallingLines
			width='100'
			visible={true}
			ariaLabel='falling-lines-loading'
			color='#A0D9D9'
		/>
	);
};

export default Spinner;
