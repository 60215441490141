import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import Label from '../label/Label';
import { Modal } from 'modal-library-ludogwada';
import Spinner from '../spinner/spinner';

const Form = () => {
	const form = document.getElementById('form');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [message, setMessage] = useState('');
	const [errorName, setErrorName] = useState();
	const [errorEmail, setErrorEmail] = useState();
	const [errorMessage, setErrorMessage] = useState();
	const [showModal, setShowModal] = useState(false);
	const [loading, setLoading] = useState(false);

	const fieldNameValided = () => {
		if (lastName.length < 2) {
			setErrorName(true);
		} else setErrorName(false);
	};
	const fieldMessageValided = () => {
		if (message.length < 2) {
			setErrorMessage(true);
		} else setErrorMessage(false);
	};
	const fieldEmailValided = () => {
		if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
			setErrorEmail(true);
		} else setErrorEmail(false);
	};
	const sendEmail = () => {
		let params = {
			name: lastName,
			email: email,
			phone: phone,
			message: message,
		};
		emailjs
			.send('service_0trcweq', 'template_acc4h8r', params, 'UkL1-EhagRtRPBvHL')
			.then(
				(result) => {
					setLoading(false);
					setShowModal(true);
					setLastName('');
					setEmail('');
					setPhone('');
					setMessage('');
					form.reset();
					console.log(result.text);
				},
				(error) => {
					console.log(error.text);
				}
			);
	};
	const sendMessage = () => {
		if (errorName === false && errorEmail === false && errorMessage === false) {
			console.log(lastName, email, phone, message);
			sendEmail();
		}
	};

	const Validation = (e) => {
		e.preventDefault();
		setLoading(true);
		sendMessage();
		fieldNameValided();
		fieldMessageValided();
		fieldEmailValided();
	};

	return (
		<form id='form' onSubmit={Validation}>
			<Label
				input
				htmlFor='name'
				title='Nom'
				type='text'
				name={lastName}
				set={setLastName}
				error={errorName}
				class='input'
				check={fieldNameValided}
			/>
			<Label
				input
				htmlFor='email'
				title='Email'
				type='email'
				name={email}
				set={setEmail}
				error={errorEmail}
				class='input'
				check={fieldEmailValided}
			/>

			<Label
				input
				htmlFor='phone'
				title='Téléphone'
				type='tel'
				name={phone}
				set={setPhone}
				class='input'
			/>
			<Label
				htmlFor='message'
				title='Message'
				type='text'
				name={message}
				set={setMessage}
				error={errorMessage}
				class='textarea'
				check={fieldMessageValided}
			/>
			<input type='submit' value='Envoyer' className='button' />
			{loading ? <Spinner /> : ''}
			<Modal
				modalStyle='modal'
				textStyle='text'
				showModal={showModal}
				setShowModal={setShowModal}
				content='Message envoyé'
			/>
		</form>
	);
};
export default Form;
