import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../components/button/Button';
import Video from '../components/video/Video';

const Cover = () => {
	const navigate = useNavigate();

	useEffect(() => {
		const redirection = setTimeout(() => {
			navigate('/home');
		}, 4000);

		return () => {
			clearTimeout(redirection);
		};
	}, [navigate]);

	const entrance = () => {
		navigate('/home');
	};
	return (
		<main className='cover'>
			<section className='video'>
				<Video />
			</section>
			<Button action={entrance} text='Entrer' />
			<h1 className='title'>Coco et Cajou Bungalows</h1>
			<h2 className='title'>Location de bungalows en Guadeloupe</h2>
		</main>
	);
};

export default Cover;
